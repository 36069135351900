<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image algorithm-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Algorithm</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title">
                        <h2 class="heading-title">사용자 취향을 예측하는</h2>
                        <h2 class="heading-title">데이터 기반 맞춤형 추천 알고리즘</h2>
                        <p class="description pt--80" align="justify">
                          데이터 수집을 기반으로 한 고객 맞춤형 추천 시스템은 사용자의 취향 및 행동 패턴을 심층적으로 분석하여, 개인화된 상품이나 서비스를 제안하는 역할을 합니다. 이 과정에서 추천 알고리즘을 활용하여, 사용자의 과거 상호작용, 구매 이력, 검색 패턴 등 다양한 데이터를 분석하고 이를 통해 맞춤형 제안을 도출합니다.
                        </p>
                        <p class="description" align="justify">
                          이러한 추천들은 주로 챗봇 인터페이스나 사용자 맞춤 추천 섹션을 통해 제시되며, 사용자의 반응과 피드백은 지속적인 알고리즘 학습과 개선을 위한 중요한 데이터 소스로 활용됩니다. 이는 추천 시스템의 정확도와 효율성을 지속적으로 향상시키는 데 기여합니다.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12 mt--30 mb--120">
                    <div class="thumb position-relative">
                      <div class="thumbnail position-relative">
                        <img class="w-100" src="../../assets/images/technology/algorithm01.png" alt="" />
                      </div>
                    </div>
                  </v-col>
                  
                  <v-col lg="12" md="12" cols="12">
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start Counterup Area  -->
    <div class="rn-counterup-area ptb--120 bg_color--5">
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="text-center section-title">
            <h4 class="heading-title">Select Models</h4>
            <p class="description">
              복잡한 데이터 세트에서 의미 있는 패턴과 관계를 식별하여 효율적인 의사결정과 개인화된 사용자 경험을 제공할 수 있습니다.<br />
              데이터 전문가들과 상의해보세요.
            </p>
          </div>
        </v-col>
        <ServiceFour />
      </v-row>
    </v-container>
  </div>
  <!-- End Counterup Area  -->





    <div class="rn-service-details ptb--120 bg_color--5" style="display:none;">
      <v-container>
        <!-- Start Single Content  -->
        <v-row class="sercice-details-content align-center row--35">
          <v-col lg="6" md="6" cols="12" order="2" order-md="1">
            <div class="details mt_md--30 mt_sm--30">
              <div class="section-title">
                <h2 class="heading-title">메인추천</h2>
                <p class="description">
                  사용자의 관심데이터와 트렌드를 바탕으로 메인화면을 통해 다양한 기업을 추천해 줍니다.
                </p>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="2" cols="12" order="1" order-md="2">
            <div class="thumb position-relative">
              <img
                class="w-100" src="../../assets/images/service/service-02.png"
                alt="Service Images"
              />
            </div>
          </v-col>
          
        </v-row>
        <!-- End Single Content  -->
      </v-container>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import ServiceFour from "../../components/tech/ServiceFour";
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import PricingPlan from "../../components/pricing-plan/PricingPlan";
  export default {
    components: {
      Header,
      Footer,
      PricingPlan,
      ServiceFour
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
