<template>
    <v-row>
      <v-col
        md="3"
        sm="6"
        cols="12"
        class="mt--30"
        v-for="(service, i) in serviceContent"
        :key="i"
      >
        <div class="standard-service">
          <div class="thumbnai">
            <img :src="service.src" alt="Corporate Images" />
          </div>
          <div class="content">
            <h4>{{ service.title }}</h4>
            
            <p align="justify">
              {{ service.desc }}
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </template>
  
  <script>
    export default {
      data() {
        return {
          serviceContent: [
            {
              src: require("../../assets/images/technology/algorithm_th01.png"),
              title: "Clustering",
              desc: `데이터를 유사한 특성을 가진 여러 그룹으로 나누는 머신러닝 기법으로, 감독 없이 데이터 내의 숨겨진 패턴을 발견하는 데 사용됩니다. 예를 들어, 고객 세분화, 유전자 분석, 이미지 분류 등 다양한 분야에서 활용됩니다. 대표적인 군집 알고리즘으로는 K-평균(K-means), 계층적 군집화(Hierarchical Clustering), DBSCAN 등이 있습니다.`,
            },
            {
              src: require("../../assets/images/technology/algorithm_th02.png"),
              title: "Similarity",
              desc: `두 객체 간의 상호 유사성을 정량적으로 평가하는 분석 기법으로, 개체의 다차원 특성 벡터를 기반으로 유사도 점수를 계산하여 객체 간의 관계 정도를 수치화합니다. 이러한 계산은 추천 시스템의 맞춤형 콘텐츠 제공, 검색 엔진의 관련 결과 최적화, 문서 분류의 정교화와 같은 고급 분석 어플리케이션에 필수적입니다.`,
            },
            {
              src: require("../../assets/images/technology/algorithm_th03.png"),
              title: "Collaborative Filtering",
              desc: `사용자의 과거 행동이나 선호도를 기반으로 추천을 제공하는 모델입니다. 주로 추천 시스템에서 사용되며, 사용자 간의 유사성 또는 항목 간의 유사성을 분석합니다. 그리고 사용자와 항목을 결합한 하이브리드 모델도 존재합니다. 예를 들어, 영화 추천, 음악 추천, 온라인 쇼핑 등에서 사용자의 경험을 개인화하는 데 효과적입니다.`,
            },
                      {
              src: require("../../assets/images/technology/algorithm_th04.png"),
              title: "Association Rules",
              desc: `항목 간의 연관성을 찾는 모델로, 주로 시장 바구니 분석에서 사용됩니다. 이 모델은 데이터 내에서 항목 간의 빈번한 조합을 찾아내고, 이를 바탕으로 규칙을 생성합니다. 대표적인 예로, "사람들이 빵을 살 때 우유도 함께 구매한다"와 같은 패턴을 발견할 수 있습니다. 이 모델의 대표적인 알고리즘으로는 Apriori, FP-Growth 등이 있습니다.`,
            },
          ],
        };
      },
    };
  </script>
  
  